import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { TabContent, TabsContainer } from "@/shared/Match.style.jsx";
import { performanceColor } from "@/shared/performance-utils.mjs";
import { getLocale } from "@/util/i18n-helper.mjs";

export type Tab = {
  label: Translation | string;
  score: number;
  subtitle?: Translation | string;
  content: React.ReactNode | (() => React.ReactNode);
  clickEvent?(): void;
};

export default function MatchGraph({ tabs }) {
  const { t } = useTranslation();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const selectTab = useCallback((event) => {
    const {
      target: {
        dataset: { key },
      },
    } = event;
    setSelectedIndex(Number.parseInt(key, 10));
  }, []);

  const content = tabs[selectedIndex]?.content;

  return (
    <div>
      <TabsContainer>
        {tabs.map(({ label, score, clickEvent }: Tab, i) => {
          return (
            <button
              className={`tab ${selectedIndex === i ? "selected" : ""}`}
              key={i}
              data-key={i}
              onClick={(e) => {
                selectTab(e);
                if (typeof clickEvent === "function") clickEvent();
              }}
              style={{ "--perf-color": performanceColor(score) }}
            >
              <span className="title type-subtitle1">
                {Array.isArray(label) ? t(...label) : label}
              </span>
              {score ? (
                <span className="score type-h6">
                  {score.toLocaleString(getLocale(), {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </span>
              ) : null}
            </button>
          );
        })}
      </TabsContainer>
      <TabContent>
        {typeof content === "function" ? content() : content}
      </TabContent>
    </div>
  );
}
